import { Container } from "reactstrap";
import {
  EnterPriseLeft,
  EnterPriseLeftH1,
  EnterPriseLeftP,
  EnterPriseMain,
  EnterPriseSub,
  SkeletonEnterPriseBackBg
} from "../../StyledComponents/HomeStyled";
export default function SkeletonEnterPrise() {
  return (
    <EnterPriseMain >
      <SkeletonEnterPriseBackBg />
      <Container>
        <EnterPriseSub>
          <EnterPriseLeft>
            <EnterPriseLeftP>Enterprise</EnterPriseLeftP>
            <EnterPriseLeftH1>
              Software Development Company Trusted by{" "}
              <br className="lg:block hidden" />
              95% of CEO.
            </EnterPriseLeftH1>
            <EnterPriseLeftP className="EnterPrise-text">
              Delivering cutting-edge web and mobile <br />
              application solutions to drive global success.
            </EnterPriseLeftP>
          </EnterPriseLeft>
        </EnterPriseSub>
      </Container>
    </EnterPriseMain>
  );
}
