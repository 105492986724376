export const getLocalStorageItem = (key) => {
  try {
    if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
      const item = localStorage.getItem(key);
      return item !== null ? JSON.parse(item) : null;
    }
  } catch (error) {
    //console.error("Error getting data from localStorage:", error);
    return null;
  }
};

export const setLocalStorageItem = (key, value) => {
  try {
    if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
      localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    //console.error("Error setting data in localStorage:", error);
  }
};

export const clearLocalStorageItem = (key) => {
  try {
    if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
      localStorage.removeItem(key);
    }
  } catch (error) {
    //console.error("Error clearing data from localStorage:", error);
  }
};
