import axios from "axios";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();
const API_URL = publicRuntimeConfig.apiBaseUrl;
const ipInfoToken = publicRuntimeConfig.ipInfoToken;

export async function fetchAPI(query = "", variables = {}) {
  const headers = { "Content-Type": "application/json" };

  try {
    // WPGraphQL Plugin must be enabled
    const res = await fetch(API_URL, {
      headers,
      method: "POST",
      body: JSON.stringify({
        query,
        variables,
      }),
      next: { revalidate: 10 },
    });

    // Check if the response is OK (status code 2xx)
    if (!res.ok) {
      console.error("Network response was not ok", res.statusText);
      return {};
    }

    // Parse the response as JSON
    const json = await res.json();

    // Check for GraphQL errors
    if (json.errors) {
      console.error("GraphQL errors:", json.errors);
      return {};
    }

    // Return the data from the response
    return json.data;
  } catch (error) {
    // Handle fetch errors (network issues, etc.)
    console.error("Fetch failed:", error);
    return {};
  }
}

export const fetchIPDetails = async (ip) => {
  return await axios.get(`https://ipinfo.io/${ip}?token=${ipInfoToken}`)
}
